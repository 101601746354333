<template>
    <div class="pdl-create">
        <PageHeading :breadcrumbs="breadcrumbs" title="Name PDL Convo" />

        <form>
            <Input
                id="name"
                v-model="name"
                type="text"
                name="name"
                label="PDL Convo Name"
                :max="152"
                :show-remaining-char="true"
                :disabled="false"
                :errors="[]"
            />
        </form>

        <Stepper
            show-back-button
            show-next-button
            :nav-is-opened="navIsOpened"
            :next-button-is-disabled="name === ''"
            @backButtonOnClick="onBackButtonClick"
            @nextButtonOnClick="onNextButtonClick"
        />

        <Dialog
            show-confirm-button
            confirm-button-text="Discard changes"
            close-button-text="Continue Editing"
            :is-dialog-visible="showCancelDialog"
            @onClose="leaveDialogOnConfirm"
            @onClickOutside="leaveDialogOnConfirm"
            @closeOnEscapeEvent="leaveDialogOnConfirm"
            @confirmButtonOnClick="leaveDialogOnClose"
        >
            <template #header>There are unsaved changes made to the PDL convo.</template>
            <template #body>Continue editing to save progress.</template>
        </Dialog>
    </div>
</template>

<script>
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import PageHeading from '@/components/ui/PageHeading';
import { mapGetters, mapActions } from 'vuex';
import Stepper from '@/components/ui/Stepper';
import Input from '@/components/forms/Input';
import Dialog from '@/components/ui/Dialog';
import { ConvoTypes } from '@/store/enums';

export default {
    name: 'PDLCreate',
    components: {
        PageHeading,
        Stepper,
        Dialog,
        Input,
    },
    props: {
        navIsOpened: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            breadcrumbs: [
                new Breadcrumb('PDL convos', { name: 'pdl-list' }),
                new Breadcrumb('Name PDL convo'),
            ],
            showCancelDialog: false,
        };
    },
    computed: {
        ...mapGetters('convoDetails', ['convoDetailsView']),
        name: {
            get() {
                return this.convoDetailsView.convo.name;
            },
            set(value) {
                this.setConvoName(value);
            },
        },
    },
    created() {
        this.resetConvoDetailsState();
        this.setConvoType(ConvoTypes.PDLConvo);
    },
    methods: {
        ...mapActions('convoDetails', [
            'saveConvo',
            'setConvoName',
            'validateConvo',
            'setConvoType',
            'resetConvoDetailsState',
        ]),

        onBackButtonClick() {
            if (this.name === '') {
                this.$router.push({ name: 'pdl-list' });
                return;
            }
            this.showCancelDialog = true;
        },
        leaveDialogOnClose() {
            this.$router.push({ name: 'pdl-list' });
            this.name = '';
        },
        leaveDialogOnConfirm() {
            this.showCancelDialog = false;
        },
        async onNextButtonClick() {
            if (await this.validateConvo()) {
                await this.saveConvo({ name: this.name, isPdl: true });
                this.$router.push({
                    name: 'pdl-edit',
                    params: {
                        convoId: this.convoDetailsView.convo.id,
                    },
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.pdl-create {
    margin: 0 auto;
    max-width: 600px;
}
</style>
